<template>

  <section id="cultura-valor-form">

    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
          <font-awesome-icon 
            icon="square-plus" 
            class="color-secondary"
          />
            Publica un nuevo Valor
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-xl-6 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              <font-awesome-icon 
                icon="square-pen" 
                class="color-secondary"
              />
              Datos del valor
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Nombre</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      maxlength="20" 
                      v-model="document_title" 
                      ref="albumTitle"
                    />
                    <div class="form-text text-end">
                      {{ document_title.length }} /20
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Descripción</label>
                    <textarea 
                      class="form-control textarea-custom" 
                      maxlength="800"
                      v-model="document_desc"
                      rows="3"
                    >
                    </textarea>
                    <div class="form-text text-end">
                      {{ document_desc.length }}/800
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Asigna un color al valor
                    </label>

                    <div class="wall-modal-perfil__input-container">
                      <div class="wall-modal-perfil__colors">
                        <div
                          v-for="(co, i) in colors"
                          :key="i"
                          class="wall-modal-perfil__color"
                          :style="`background: ${co};`"
                          :class="document_color == co && 'picked'"
                          @click="document_color = co"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              <font-awesome-icon 
                icon="image" 
                class="color-secondary"
              />
              Imagen del valor
                <font-awesome-icon 
                  :icon="['fa-regular','circle-question']"
                  content="Aquí podrás seleccionar las imágenes en formato JPG/PNG"
                  v-tippy="{ arrow: true }"
                />
            </h5>
            <UploadImg 
              @sendImg="handleMainImg" 
              v-if="news_main_img.base64 == ''"
            />
              <div 
                v-if="news_main_img.base64 !== ''"
                class="upload-image"
              >
                <div class="upload-image-preview">
                  <button 
                    @click="deletePreview('main')"
                      class="upload-image-preview-remove"   
                  >
                      <font-awesome-icon icon="times"/>
                  </button>
                  <img 
                      class="upload-image-preview-img"
                      :src="news_main_img.base64"/>
                </div>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="cancelEdit()"
              >
              Cancelar
              </button>
              <button
                v-if="editing"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="editValorCultura()"
                :disabled="!itemsChanged()"
              >
                Guardar
              </button>
              <button
                v-if="!editing"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="addValorCultura()"
                :disabled="!itemsFilled()"
              >
                Agregar
              </button>
            </div>
          </div>
        </div> 

      </div>
    </div>

    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Spinner v-if="show_spinner"/>
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
import { GET_CULTURA_ID, GET_CULTURAS_VALOR } from "@/apollo/queries";
import UploadImg from "../UploadImg.vue";
import { mapActions, mapState} from "vuex";
import Spinner from "../Spinner.vue";

export default {
  components: { Status, Spinner, UploadImg },
  data() {
    return {
      document_title: "",
      document_desc:'',
      document_color:'',
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      image_uploaded: {
        base64: "",
        src: "",
        format: "",
      },
      colors:['#0072f7','#357BF3','#37C1EB','#05CFC1','#23BE61','#F6C700','#F26F28','#E64141','#EC206B'],
      show_spinner: false,
      edit_file: null,
      file_limit_mb: false,
      subtitle: "",
      doc_edit: {},
      no_internet: false,
      news_main_img : {
        width:    '',
        height:   '',
        base64:   '',
        src:      '',
        format:   '',
      },
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      id_cultura:'',
      editing:false,
      to_edit:{},
      is_file: false
    };
  },
  mounted() {
    this.checkState();
    this.getCultura();
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      this.getValorCultura();
      this.editing = true;
    }
  },
  computed: {
    ...mapState("cultureModule",["culture"])
  },
  methods: {
    ...mapActions("cultureModule", ["addValor", "editValor"]),
    checkState(){
      if(this.culture.valores == undefined){
        this.goBackToList();
      }
    },
    getValorCultura() {
      this.$apollo
        .query({
          query: GET_CULTURAS_VALOR,
          variables: {
            id_empresa: this.id_empresa, //id segun la empresa, cuando este ok el login
          },
        })
        .then((res) => {
          var resp = res.data.culturas.valores;
          this.to_edit = resp.filter(li => li.id_valor == this.$route.params.id)[0]
          this.document_title = this.to_edit.nombre;
          this.document_desc = this.to_edit.descripcion;
          this.document_color = this.to_edit.color;
          this.news_main_img.base64 = this.to_edit.imagen_url;
          this.news_main_img.src = this.to_edit.imagen_url;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    goBackToList(){
      this.$router.push({
        name:'culture-list'
      });
    },
    getCultura() {
      this.$apollo
        .query({
          query: GET_CULTURA_ID,
          variables: {
            id_empresa: this.id_empresa, //id segun la empresa, cuando este ok el login
          },
        })
        .then((res) => {
          var resp = res.data.culturas.id_cultura;
          this.id_cultura = resp
         
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    cleanInputs() {
      this.toggle_switch_btn = false;
      this.document_title = "";
      this.document_desc = "";
      this.document_color = "";
      this.news_main_img = {
        width:    '',
        height:   '',
        base64:   '',
        src:      '',
        format:   '',
      };
    },
    cancelEdit() {
      this.cleanInputs();
      this.$router.push({
        name: "culture-list",
      });
    },
    closeStatus() {
      this.open_modal_status = false;
      if (this.file_limit_mb) {
        this.document_uploaded = {
          pdf: "",
          pdf_prev: "",
          name: "",
          format: "",
        };
      } else {
        if (this.no_internet == false) {
          this.$router.push({
            name: "culture-list",
          });
        } else {
        this.no_internet = false;
      }}
    },
    // Agregar nuevo documento
    addValorCultura() {
      if (this.itemsFilled()) {
        this.show_spinner = true;
        let data_ = {
          nombre:this.document_title,
          descripcion:this.document_desc,
          color:this.document_color,
          imagen:this.news_main_img.src,
          formato_imagen:this.news_main_img.base64.split(":")[1].split(";")[0],
          id_cultura:this.id_cultura,
          id_empresa:this.id_empresa,
          id_usuario:this.id_usuario,
        }
        this.addValor(data_).then(res =>{
          if(res != null){
            this.$toast.open({
              message:'Valor agregado correctamente.',
              type:'success',
              position:'top-right',
              duration:5000
            });
            this.goBackToList();
          }else{
            this.$toast.open({
              message:'Ocurrió un error al agregar el valor, intentalo nuevamente.',
              type:'error',
              position:'top-right',
              duration:5000
            })
          }
        })
      }
    },
    // Agregar nuevo documento
   async editValorCultura() {
      if (this.itemsFilled()) {
        this.show_spinner = true;
        let image_ext = '';
        let image = '';
        if(this.is_file){
          image_ext = this.news_main_img.base64.split(":")[1].split(";")[0];
          image = this.news_main_img.base64;
        }
        let data_ = {
              nombre:this.document_title,
              descripcion:this.document_desc,
              color:this.document_color,
              imagen:image,
              formato_imagen: image_ext,
              id_cultura:this.id_cultura,
              id_empresa:this.id_empresa,
              id_usuario:this.id_usuario,
              id_valor:this.to_edit.id_valor
        }
        await this.editValor(data_).then(res =>{
          if(res != null){
            this.$toast.open({
              message:'Valor editado correctamente.',
              type:'success',
              position:'top-right',
              duration:5000
            });
            this.goBackToList();
          }else{
            this.$toast.open({
              message:'Ocurrió un error al editar el valor, intentalo nuevamente.',
              type:'error',
              position:'top-right',
              duration:5000
            })
          }
        });
      }
    },
    imgValidate(img){
      if(img.includes('https://c3repo.cl/')){
        return '';
      }else{
        return img;
      }
    },
    //select imagen
    handleMainImg(img) {
      this.is_file = true;
      this.news_main_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
      };
      // console.log(this.post_img);
    },
    deletePreview(img_type) {
      if (img_type == "main") {
        this.news_main_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
        };
      }
    },
    // funcion que verifica que se haya llenado el formulario al crear un nuevo doc
    itemsFilled() {
      if (  this.document_title.length > 0 && 
            this.document_desc.length > 0 && 
            this.document_color.length > 0 && 
            this.news_main_img.src != '') {
        if (
          this.id_cultura.src != ''
        ) {
          return true;
        } else false;
      } else false;
    },
    itemsChanged(){
      return true;
    },
  },
};
</script>